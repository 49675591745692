@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");
@import "./colors";
@import "./mixing";
@import "./nav-bar";
@import "./searh-page";
@import "./result-page";
@import "./aside-section";
@import "./paggination";
* {
    box-sizing: border-box;
}
body {
    font-family: 'Outfit', sans-serif;
    margin: 0px;
    background-color: $body;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1400px) {
        max-width: 1320px;
    }
}
.mr-2{
    margin-right: .5rem;
}
.bg-white{
    background-color: $white;
    padding: .5rem;
}
.p-2{
    padding: 1rem;
}
.heading-orange{
    color: #d94220;
    @include webFont;
    font-weight: 300;
    text-align: center;
    margin-top: .5rem;
}
.details-paragraph{
    @include webFont;
    max-width: 500px;
    margin: auto;
    text-align: center;
}
.orange-btn-details{
    background-color: #d94220;
    border:1px solid #d94220;
    @include webFont;
    max-width: 200px;
    width: 100%;
    height: 48px;
    margin: 2.5rem auto;
    border-radius: 5px;
    font-size: 1.2rem;
    color: white;
    display: block;
}
button {
    transition: all .2s ease;
    cursor: pointer;
}