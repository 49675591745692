nav.main-nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    pointer-events: none;

    > * {
        pointer-events: all;
    }
    
    @media (max-width: 768px) {
        padding: 30px 15px;
    }

    .logo {
        width: 40px;
        height: 40px;
        border-radius: 5px;
    }
    .menu-trigger-btn {
        border: 1px solid $border1;
        height: 40px;
        width: 40px;
        border-radius: 8px;
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #f4f4f4;
    width: 100%;
    max-width: 500px;
    height: 100%;
    padding-bottom: 50px;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform .3s ease;
    z-index: 2;

    &.show {
        transform: translateX(0);
    }

    .top-companies {
        padding: 100px 30px 30px;
        background-color: #efefef;

        h3 {
            font-size: 36px;
            line-height: 1.4;
            margin: 0 0 20px;
            font-weight: 500;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 30px;

            @media (max-width: 400px) {
                justify-content: space-between;
                gap: 0;
            }

            img {
                width: 60px;
                height: 60px;
                object-fit: contain;
                background-color: #fff;
                border-radius: 30px;
            }
        }
    }

    .related-brands {
        padding: 30px;

        h3 {
            font-size: 24px;
            line-height: 1.4;
            margin: 0 0 10px;
            font-weight: 500;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;

            li {
                padding: 5px 0;
            }

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: #000;
            }

            img {
                width: 60px;
                height: 60px;
                display: block;
                object-fit: contain;
                border-radius: 30px;
                background-color: #fff;
            }

            span {
                margin-left: 15px;
            }

            em {
                font-size: 12px;
                font-weight: 300;
                font-style: normal;
                white-space: nowrap;
            }
        }
    }

    .nav-links {
        padding: 30px 15px;

        button {
            background: #FFFFFF;
            border-radius: 10px;
            border: 0;
            display: block;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #919498;
            text-align: left;
            padding: 7px 30px;
            margin: 8px 0;

            .link-icon {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                background-color: #efefef;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
            }
        }
    }

    .copyright {
        font-size: 14px;
        line-height: 1.4;
        margin: 3px 0;
        text-align: center;
    }
}