.aside-section {
    padding-left: 15px;

    .loader {
        width: 140px;
        height: 512px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F4F4F4;

        &.hide {
            display: none;
        }

        svg {
            width: auto;
            height: 60px;
        }
    }
    .heading {
        @include webFont;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: $light1;
        margin-top: 26px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        display: none;
    }
    .top-search {
        list-style-type: none;
        padding-left: 10px;
        margin: 0;
        max-height: 512px;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
        
        li {
            margin-bottom: 16px;
            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 50px;
                    margin-right: 8px;
                    background-color: #fff;
                    border-radius: 25px;
                    overflow: hidden;
                    
                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        background-color: #fff;
                    }
                }
                .title {
                    @include webFont;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $dark1;
                }
            }

        }
    }
}
