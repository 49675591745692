.resul-page {
    .top-section {
        background-color: $light2;
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media(max-width:768px){
            height: auto;
            padding-top: 85px;
        }

        .company-details {
            display: flex;
            
            .company-icon {
                height: 60px;
                width: 60px;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 768px) {
                    margin-right: 10px;
                }

                img {
                    width: 35px;
                    height: 35px;
                    object-fit: contain;
                    background-color: #fff;
                    border-radius: 5px;
                }
            }
            .company-name {
                @include webFont;
                font-size: 24px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                margin-top: 16px;
            }
            .company-info {
                margin-bottom: 25px;
                display: flex;
                &-item {
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    @media(max-width:768px){
                        margin-right: 15px;

                    }
                    svg {
                        margin-right: 4px;
                        width: 12px;
                        height: 12px;
                    }
                    span {
                        @include webFont;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: $light1;
                    }
                }
            }
        }
    }
    .bottom-section {
        .row {
            display: flex;
            flex-wrap: wrap;
            .main-section {
                max-width: 770px;
                flex: 1;
                width: 100%;
                padding-right: 15px;
                @media (max-width: 768px) {
                    padding-right: 0;
                }
                .main-section-head {
                    margin-bottom: 10px;
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    .d-flex {
                        display: flex;
                        align-items: center;
                        .back-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            background: $light2;
                            border-radius: 30px;
                            border: none;

                            &:hover {
                                background-color: #fff;
                            }
                        }
                        .result-title {
                            @include webFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: $light1;
                            margin-left: 16px;
                        }
                    }
                    .filter-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        background: $light2;
                        border-radius: 30px;
                        border: 0px;

                        &:hover {
                            background-color: #fff;
                        }
                    }
                }
                .card-mail {
                    height: 44px;
                    background: $white;
                    border-radius: 10px;
                    padding: 13px 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 8px;
                    cursor: pointer;
                    transition: background-color .2s ease;

                    &:hover {
                        background-color: #fbfbfb;
                    }
                    
                    .card-heading {
                        @include webFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: $dark2;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .card-date {
                        @include webFont;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: right;
                        color: $light1;
                        min-width: 75px;
                    }
                }
            }
         
        }
    }

    iframe {
        width: 100%;
        min-height: calc(100vh - 320px);
        outline: none;
        border: none;
        background-color: #fff;
        padding: 0;
        border-radius: 16px;
    }

    .mail-filter {
        display: block;
        padding: 20px 0;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #f2f2f2;
        z-index: 3;
        box-shadow: 0 0 5px 2px rgb(0 0 0 / 5%);
        max-width: 315px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        transform: translateX(100%);
        transition: transform .2s ease;

        &.active {
            transform: translateX(0);
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .quick-selection {
            margin: 15px;
            
            &:after {
                content: '';
                display: table;
                clear: both;
            }

            button {
                background: #EFEFEF;
                border-radius: 30px;
                border: none;
                color: #919498;
                padding: 5px;
                display: block;
                float: left;
                width: calc(50% - 10px);
                margin: 5px;

                &:hover {
                    background-color: #fff;
                }

                &:first-child {
                    width: calc(100% - 10px);
                }

                &.active {
                    color: $primary;
                    background-color: #fff;
                }
            }
        }

        .buttons {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
        }

        .filter-button {
            background-color: #00C46F;
            border: 1px solid #00C46F;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            color: #ffffff;
            border-radius: 30px;
            padding: 8px 28px;
            cursor: pointer;
            width: 100%;
        }

        .close-button {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            border-radius: 30px;
            padding: 8px 28px;
            cursor: pointer;
            background: #EFEFEF;
            border-radius: 30px;
            border: none;
            color: #919498;
            width: 100%;
        }
    }

    .result-main {
        .loader {
            width: 100%;
            height: 512px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F4F4F4;
    
            &.hide {
                display: none;
            }
    
            svg {
                width: auto;
                height: 60px;
            }

            &:not(.hide) + .mail-box {
                display: none;
            }
        }
    }
}

.forward-email {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;

    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f5f5f5;
        z-index: 1;
    }

    .form {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        width: 640px;
        max-width: 100%;
        padding: 2vw 4vw;

        label {
            display: block;
            font-weight: 500;
            font-size: 70px;
            font-size: clamp(50px, 15vw, 70px);
            color: $primary;
            line-height: 1.4;
            margin-bottom: 10px;

            img {
                vertical-align: middle;
                margin-left: 20px;
                margin-top: -10px;
            }
        }

        p {
            width: 100%;
            padding: 1vw;
            text-align: center;
            background-color: #fff;
            border-radius: 16px;
            color: $primary;
            font-weight: 500;
        }

        input {
            display: block;
            font-size: 15px;
            line-height: 1;
            font-weight: 500;
            border: none;
            outline: none;
            text-align: center;
            border-radius: 30px;
            padding: 21px 2vw;
            margin-bottom: 30px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 10px;

            button {
                display: block;
                border: none;
                outline: none;
                padding: 17px 2.5vw;
                border-radius: 35px;
                font-weight: 500;
                color: #919498;
                background-color: #EFEFEF;
                cursor: pointer;

                &:hover {
                    background-color: #fff;
                }

                &.send-email {
                    background-color: $primary;
                    color: #fff
                }

                &.success-btn {
                    background-color: $primary;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}