.searh-page {
    .top-section {
        height: 420px;
        width: 100%;
        border-radius: 0px;
        background: $light2;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .contant {
            max-width: 800px;
            width: 100%;
            padding: 0px 15px;
            margin-bottom: -25px;
            h1 {
                @include webFont;
                font-size: 30px;
                font-weight: 500;
                line-height: 38px;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 8px;
                margin-top: 0px;
            }
            h3 {
                @include webFont;
                font-size: 24px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 48px;
                margin-top: 0px;
            }
            .search-bar {
                width: 100%;
                position: relative;
                padding: 5px;
                background-color: $white;
                border-radius: 30px;
                display: flex;
                align-items: center;
                z-index: 1;

                input {
                    height: 50px;
                    flex: 1;
                    border: 0px;
                    padding-left: 30px;
                    width: 100%;
                    border-radius: 30px;

                    &::-webkit-input-placeholder,
                    &:-ms-input-placeholder,
                    &::placeholder {
                        @include webFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #c4c4c4;
                    }
                    &:active,
                    &:focus-visible {
                        outline: none;
                    }
                }

                button {
                    width: 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $primary;
                    border: 1px solid $primary;
                    @include webFont;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15px;
                    letter-spacing: 0em;
                    color: $white;
                    border-radius: 30px;
                    padding: 8px 28px;
                    cursor: pointer;
                    height: 50px;
                    
                    svg {
                        margin-right: 4px;

                        @media (max-width: 768px) {
                            margin-right: 0;
                        }
                    }
                    @media(max-width:768px){
                        max-width: 50px;
                        padding: 8px 10px;
                    }
                }

                > .react-autosuggest__container {
                    flex: 1;

                    .react-autosuggest__suggestions-container {
                        position: absolute;
                        background-color: #fafafa;
                        max-height: 380px;
                        overflow-y: scroll;
                        left: 25px;
                        right: 25px;
                        top: 60px;
                        border-radius: 0 0 30px 30px;
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                
                        &::-webkit-scrollbar {
                            display: none;
                        }

                        ul.react-autosuggest__suggestions-list {
                            list-style: none;
                            margin: 0;
                            padding: 10px;

                            li {
                                color: #000;
                                font-size: 15px;
                                font-weight: 500;
                                padding: 0.45vw;
                                border-radius: 8px;
                                cursor: pointer;

                                &.react-autosuggest__suggestion--highlighted {
                                    background-color: #cff1dd;
                                }

                                div {
                                    display: flex;
                                    align-items: center;

                                    img {
                                        width: 60px;
                                        height: 60px;
                                        display: block;
                                        object-fit: contain;
                                        border-radius: 30px;
                                        background-color: #fff;
                                    }

                                    span {
                                        margin-left: 1.2vw;
                                    }

                                    em {
                                        font-size: 12px;
                                        font-weight: 300;
                                        font-style: normal;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 768px) {
            height: 352px;
            .contant {
                h3 {
                    font-weight: 300;
                }
                .search-bar {
                    button {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .bottom-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 420px);

        @media (max-width: 768px) {
            height: calc(100vh - 170px);
        }

        .lower-menu {
            max-width: 258px;
            width: 100%;
            margin: 70px auto 0px;
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style-type: none;
                li {
                    position: relative;

                    &::before {
                        content: "Coming Soon!";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: -30px;
                        background-color: $primary;
                        color: #fff;
                        white-space: nowrap;
                        padding: 7px 30px;
                        border-radius: 30px;
                        font-size: 14px;
                        transform: translateX(-50%);
                        z-index: 1;
                        transition: all .3s ease;
                        opacity: 0;
                        visibility: hidden;
                    }

                    &::after {
                        content: "";
                        width: 0;
                        height: 0;
                        border: 8px solid transparent;
                        border-top-color: $primary;
                        position: absolute;
                        left: 50%;
                        top: 2px;
                        transform: translateX(-50%);
                        z-index: 1;
                        transition: all .3s ease;
                        opacity: 0;
                        visibility: hidden;
                    }

                    &.coming-soon {
                        &::before, &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    a {
                        position: relative;
                        @include webFont;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #adb1b7;
                        text-decoration: none;
                        padding: 8px;
                        display: block;
                        &.active {
                            color: $dark2;
                            &::before {
                                content: "";
                                width: 22.75px;
                                height: 8.49px;
                                background-image: url("../img/Vector.svg");
                                position: absolute;
                                bottom: 0px;
                                left: 0px;
                            }
                        }
                    }
                }
            }
        }
        .top-companies {
            margin: 39px auto 0px;
            max-width: 510px;
            width: 100%;

            @media (max-width: 768px) {
                margin-top: 20px;
            }

            .loader {
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F4F4F4;

                &.hide {
                    display: none;
                }

                svg {
                    width: auto;
                    height: 60px;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: space-around;
                align-items: center;
                list-style-type: none;
                flex-wrap: wrap;
                @media (max-width: 768px) {
                    justify-content: center;
                    gap: 10px;
                }
                li {
                    @media (min-width: 769px) {
                        padding: 10px 10px;
                    }
                    a {
                        display: flex;
                        height: 60px;
                        width: 60px;
                        border-radius: 30px;
                        background-color: $white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border-radius: 50%;
                        }

                        @media (max-width: 768px) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
        }
        .bottom-info {
            max-width: 770px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: auto auto 190px auto;
            @media(max-width:768px){
                padding: 15px;
            }
            .info-item {
                span {
                    @include webFont;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    display: block;
                    color: $light1;
                    margin-top: 6px;
                }
                @media (max-width: 768px) {
                    flex: 1 1 50%;
                    width: 50%;
                    margin-top: 20px;
                }
            }
        }
    }
}