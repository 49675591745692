.paggination {
    max-width: 447px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 55px auto;
    button {
        @include webFont;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        color: $dark1;
        border: none;
        background-color: transparent;
        padding: 0 15px;
        &.disable{
            color: #bcbcbc;
            cursor: default;
        }
        &.active{
            color: $primary;
        }
        &:not(.disable):hover {
            color: $primary;
        }
    }
}
